import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { graphql } from "gatsby";
import { CheckboxesBasic, CheckboxesInline } from "../../examples/components/Checkboxes";
export const query = graphql`
  query CheckBoxQuery {
    Form: componentMetadata(displayName: { eq: "Form" }) {
      ...ComponentApi_metadata
    }
    FormCheck: componentMetadata(displayName: { eq: "FormCheck" }) {
      ...ComponentApi_metadata
    }
    FormCheckInput: componentMetadata(displayName: { eq: "FormCheckInput" }) {
      ...ComponentApi_metadata
    }
    FormCheckLabel: componentMetadata(displayName: { eq: "FormCheckLabel" }) {
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Overview = makeShortcode("Overview");
const CodeBlock = makeShortcode("CodeBlock");
const LinkedHeading = makeShortcode("LinkedHeading");
const ComponentApi = makeShortcode("ComponentApi");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Overview mdxType="Overview">
  Checkboxes use the <code>Form.Check</code> as a wrapper and adding{" "}
  <code>custom</code> prop. Custom checkbox and radio styles are achieved with a
  resourceful use of the <code>:checked</code> selector and <code>:after</code>{" "}
  pseudo elements, but are Structurally similar to the default{" "}
  <code>FormCheck</code>.
    </Overview>
    <CodeBlock code={CheckboxesBasic} mdxType="CodeBlock">
  <p>
    Apply Bootstrap's custom elements by adding the <code>custom</code> prop.
  </p>
    </CodeBlock>
    <CodeBlock title="CheckboxesInline" code={CheckboxesInline} mdxType="CodeBlock"></CodeBlock>
    <LinkedHeading h="2" className="h1" id="cb-api" mdxType="LinkedHeading">
  API
    </LinkedHeading>
    <ComponentApi metadata={props.data.Form} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.FormCheck} exportedBy={props.data.Form} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.FormCheckInput} exportedBy={props.data.FormCheck} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.FormCheckLabel} exportedBy={props.data.FormCheck} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      